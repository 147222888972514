<template>
  <container>
    <div class="flex flex-grow h-full items-center flex-col justify-center">
      <fenced-title
          class="mr-0 mb-2 self-stretch"
          color="fence-gray"
          textAlign="center"
          :closed="true"
      >LOGIN
      </fenced-title>
      <Form @submit="onSubmit" class="w-96">
        <div class="form-control mt-6">
          <label for="wallet">Your Username</label>
          <div class="outlined-input mt-2">
            <Field
                type="text"
                id="wallet"
                name="wallet"
                class="border-none text-black w-full h-13 text-md pl-4 rounded inline-flex flex-grow"
                placeholder="Your username"
            />
          </div>
          <ErrorMessage name="wallet" class="text-sm font-bold"/>
        </div>

        <div class="form-control mt-6">
          <label for="password">Password</label>
          <div class="outlined-input mt-2">
            <Field
                type="password"
                id="password"
                name="password"
                class="border-none text-black w-full h-13 text-md pl-4 rounded inline-flex flex-grow"
                placeholder="Your password"
                :rules="validatePassword"
            />
          </div>
          <ErrorMessage name="password" class="text-sm font-bold"/>
        </div>

        <button type="submit" class="btn primary my-6 w-full">Login</button>
      </Form>
    </div>
  </container>
</template>

<script>
import {Form, Field, ErrorMessage} from 'vee-validate';
import Container from "@/components/Container";
import FencedTitle from "@/components/FencedTitle.vue";
import {useStore} from "vuex"
import {useRouter} from "vue-router";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
    Container,
    FencedTitle
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const setConstants = () => {
      store.dispatch('collectable/setConstants');
    }
    const onSubmit = function (values) {
      store.dispatch('user/login', values)
          .then(isSuccess => {
            if (isSuccess) {
              router.push('/');
              setConstants();
            }
          })
    }
    const validateEthereumAddress = function (address) {
      return (/^(0x){1}[0-9a-fA-F]{40}$/i.test(address)) ? true : 'This is not a valid ETH address!';
    }
    const validatePassword = function (password) {
      return (password && password.length) > 4 ? true : 'Password too short';
    }
    return {onSubmit, validateEthereumAddress, validatePassword}
  }
}
</script>

<style scoped>

</style>
